import request from '../../utils/request'

interface GetProgram {
  size: number;
  current: number;
  [propName: string]: any;
}
//获取文章接口分页
export function getNewsList(data: GetProgram): Promise<any> {
  return request({
    method: 'GET',
    type: 'params',
    data: data,
    url: 'manage/JkglArticle/list'
  })
}

// 新增文章接口
export function addNews(data: Program) {
  return request({
    url: 'manage/JkglArticle/add',
    method: 'PUT',
    data: data
  })
}
// 修改文章接口
export function editNews(data: Program) {
  return request({
    url: 'manage/JkglArticle/update',
    method: 'POST',
    data: data
  })
}

// 删除文章接口
export function deleteNewsById(id: number) {
  return request({
    method: 'DELETE',
    url: 'manage/JkglArticle/delete',
    data:{id}
  })
}

interface Surplus {
  type: string;
  id?: string | number;
}
//查询剩余未健康测评的体检数据
export function getSurplusList(data: Surplus): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/program/regist/surplus/' + data.type,
    data: { id: data.id },
    type: 'params',
  })
}

//上传图片
export function uploadImg(file: FormData) {
  return request({
    url: 'manage/manage/upload?uploadPath=/healthy',
    method: 'POST',
    data: file
  })
}

interface Program {
  title: string;
  editor: string;
  mainImage: string;
  contentText: string;
  categoryId: string;
  id?: string | number | null;
}


// 获取详情某条方案接口
export function getDetailProgram(id: number) {
  return request({
    url: 'manage/healthPropaganda/detail?id=' + id,
    method: 'GET'
  })
}




// 文章分类
//获取文章分类数据
export function getNewsTypeFullList(data?: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/JkglArticleCategory/list',
    type: 'params',
    data
  })
}
//根据套餐节点id进行删除操作
export function deleteNews(data: any): Promise<any> {
  return request({
    url: 'manage/JkglArticleCategory/delete',
    method: 'DELETE',
    data
  })
}
// 新增分类
export function addNewsType(data: any): Promise<any> {
  return request({
    url: 'manage/JkglArticleCategory/add',
    method: 'PUT',
    data
  })
}
// 新增分类
export function editNewsType(data: any): Promise<any> {
  return request({
    url: 'manage/JkglArticleCategory/update',
    method: 'POST',
    data
  })
}
