

























































































































































import { Component, Vue } from "vue-property-decorator";
import {
  getPackageTypeFullList,
  deleteType,
  submitType,
} from "@/serve/medicalAppointment/packageType";
import {
  getNewsTypeFullList,
  addNewsType,
  deleteNews,
  editNewsType
} from "@/serve/news/news";
import { uploadImg } from "@/serve/medicalAppointment/institutionManage";
export interface IitemType {
  id?: number;
  categoryName: string;
  sort: number;
  remark?: string;
  icon?: string;
}
@Component({
  name: "newsType",
})
export default class PackageType extends Vue {
  public form = {
    current:1,
    size:999,
    categoryName: "",
  };
  private ruleForm: IitemType = {
    categoryName: "",
    sort: 0,
    remark: "",
    id: 0,
    // icon: "",
  };
  public comboData = [];
  public itemDialog = false;
  public created() {
    this.getPackageTypeData();
  }
  private addNew() {
    this.isAdd = true
    this.ruleForm = {
      categoryName: "",
      sort: this.comboData.length + 1,
      remark: "",
      // icon: "",
      id: 0,
    };
    this.itemDialog = true;
  }
  private editSomeOne(row: IitemType) {
    this.isAdd = false
    this.ruleForm = {...row};
    this.itemDialog = true;
  }
  //获取文章表数据
  public getPackageTypeData() {
    // 获取文章列表数据
    const dataItem = { ...this.form };
    getNewsTypeFullList(this.$changNull(dataItem)).then((res: any) => {
      this.comboData = res.data.records;
    });
  }

  //删除
  public deleteRow(id: number): void {
    (this as any).$messageBox
      .confirm("您确定是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const res = await deleteNews({ id });
        if (res.success) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getPackageTypeData();
        } else {
          this.$message.error("删除失败" + res.msg);
        }
      });
  }
  // 重置功能
  public resetForm() {
    this.form.categoryName = ""
    this.getPackageTypeData();
  }
  public isAdd = false
  private submitForm(formName: string) {
    (this.$refs[formName] as any).validate((valid: any) => {
      if (valid) {
        // 判断新增还是修改
        if(this.isAdd){
          addNewsType(this.ruleForm).then((res: any) => {
          if (res.code === 200) {
            console.log(res, "res");
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.itemDialog = false;
            this.form.categoryName = "";
            this.getPackageTypeData();
          }else{
            this.$message({
              type:"warning",
              message: res.msg|| "未知错误"
            })
          }
        });
        }else{
          editNewsType(this.ruleForm).then((res: any) => {
          if (res.code === 200) {
            console.log(res, "res");
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.itemDialog = false;
            this.form.categoryName = "";
            this.getPackageTypeData();
          }else{
            this.$message({
              type:"warning",
              message: res.msg|| "未知错误"
            })
          }
        });
        }

      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  //赋值给表单img字段
  public async handleAvatarSuccess(res: { file: File }) {
    const imgs = new FormData();
    imgs.append("file", res.file);
    const file = await uploadImg(imgs);
    this.ruleForm.icon = file.data;
  }
  //限制上传格式和大小
  public beforeAvatarUpload(file: { type: string; size: number }) {
    const isJPG = file.type === "image/jpeg";
    console.log("file.type :>> ", file.type);
    const isPNG = file.type === "image/png";
    const isPG = isJPG || isPNG;
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isPG) {
      this.$message.error("上传头像图片只能是 JPG/PNG格式!");
    }
    if (!isLt2M) {
      this.$message.error("上传头像图片大小不能超过 2MB!");
    }
    return isPG && isLt2M;
  }
  private rules = {
    categoryName: [
      { required: true, message: "请输入分类名称", trigger: "blur" },
      { min: 1, max: 16, message: "长度在 1 到 16 个字符", trigger: "blur" },
    ],
    sort: [{ required: true, message: "请输入排序编号", trigger: "blur" }],
    // icon: [{ required: true, message: "请上传图标", trigger: "blur" }],
  };
}
